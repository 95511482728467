import Image from "next/image";

import { useGame } from "../../../../hooks/useGame";

export const BattleDeckItem = ({ item, type, damageTaken }) => {
  const { selectedCards } = useGame();

  if (!selectedCards) {
    return null;
  }

  return (
    <div
      className={`relative cursor-pointer ${
        +selectedCards[type]?.assetId === +item?.assetId
          ? "!shadow-2xl !shadow-white"
          : ""
      } ${damageTaken > 0 ? "invalid" : ""} `}
    >
      {damageTaken > 0 && type === "pet" ? (
        <span
          className={`fade-in absolute bottom-6 right-4 z-[999] text-xl text-red-500 `}
        >
          -{damageTaken}HP
        </span>
      ) : null}
      <div
        className={"h-[181px] w-[130px] text-center xl:h-[250px] xl:w-[180px]"}
      >
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="flex flex-1 items-center justify-center">
              {item?.img ? (
                <figure
                  className={`relative h-[90px] w-[90px] xl:h-[128px] xl:w-[128px]`}
                >
                  <Image
                    objectFit="contain"
                    layout="fill"
                    src={`${item?.img}`}
                  />
                </figure>
              ) : (
                <div className="flip-card-front h-[256px] w-[128px] bg-blue-700 text-center">
                  Img Missing
                </div>
              )}
            </div>
            <span className={`mb-1.5 text-sm`}>
              Level: {item?.attributes?.level || 1}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BattleDeckItemMobile = ({
  item,
  id,
  deckIsOpen,
  damageTaken,
  type,
}) => {
  const { selectedCards } = useGame();

  if (!selectedCards) {
    return null;
  }

  const returnTranslate = id => {
    if (id === 2)
      return "translate-x-[120px] xs:translate-x-[135px] md:translate-x-[190px]";
    if (id === 1)
      return "translate-x-[-120px] xs:translate-x-[-135px] md:translate-x-[-190px]";
    if (id === 0) return "translate-x-0";
  };

  return (
    <div
      className={`card !h-[107.1px] ${item.type === "pet" ? "z-10" : ""} ${
        deckIsOpen ? returnTranslate(id) : ""
      }  ${damageTaken > 0 ? "invalid" : ""}`}
    >
      {damageTaken > 0 && type === "pet" ? (
        <span
          className={`fade-in absolute bottom-7 right-4 z-[999] text-xl text-red-500 `}
        >
          -{damageTaken}HP
        </span>
      ) : null}
      <div className="flex flex-1 items-center justify-center">
        {item?.img ? (
          <figure
            className={`relative h-[63px] w-[63px] md:h-[89.6px] md:w-[89.6px]`}
          >
            <Image objectFit="contain" layout="fill" src={`${item?.img}`} />
          </figure>
        ) : (
          <div className="flip-card-front h-[63px] w-[63px] bg-blue-700 text-center">
            Img Missing
          </div>
        )}
      </div>{" "}
      <span className={`-mb-5 mt-2 text-sm`}>
        Level: {item?.attributes?.level || 1}
      </span>
    </div>
  );
};
