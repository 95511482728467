import { useState } from "react";
import toast from "react-hot-toast";

import { FullModal } from "../components";
import Modal from "../components/Modal";
import { LevelUpModalContent } from "../components/ModalContent/LevelUp";
import { SelectionContent } from "../components/ModalContent/Selection/Index";
import { TrainModalContent } from "../components/ModalContent/TrainModal";
import { SoundButton } from "../components/UI/Buttons/SoundButton";
import { GameMenu } from "../components/UI/GameStages/GameMenu";
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";
import { Meta } from "../layout/Meta";

const Game = () => {
  const { activeUserData } = useAuth();
  const {
    trainSelectedItem,
    setTrainSelectedItem,
    levelUpSelectedItem,
    setLevelUpSelectedItem,
  } = useGame();

  const [selectionIsOpen, setSelectionIsOpen] = useState(false);

  const [selectionType, setSelectionType] = useState<
    null | "boostsCardID" | "petsCardID" | "cannonsCardID"
  >(null);

  const handleOnClickMenu = (item: string) => {
    if (!activeUserData) {
      return toast("Please Log In To interact");
    }

    if (item.includes("selection")) {
      const type = item.split("-")[1] as
        | "boostsCardID"
        | "petsCardID"
        | "cannonsCardID";

      setSelectionType(type);
      setSelectionIsOpen(true);
      return;
    }

    return console.log("Coming Soon!");
  };

  return (
    <>
      <>
        <Meta title="Zombiecoin - Arena" description="" />
        <div className="flex min-h-[60vh] items-center justify-center lg:min-h-[60vh]">
          <div className="max-w-screen flex w-screen flex-col items-start justify-end bg-black bg-transparent">
            <div className=" flex min-h-[calc(100vh_-_150px)] w-full min-w-[300px] flex-col  justify-center md:flex-row">
              {/* <div
                className={`fixed bottom-4 left-2 z-50 animate-pulse active:animate-none`}
              >
                <SoundButton />
              </div> */}
              <GameMenu handleOnClickMenu={handleOnClickMenu} />
            </div>
          </div>
        </div>
      </>

      {selectionType !== null && (
        <FullModal isOpen={selectionIsOpen} setIsOpen={setSelectionIsOpen}>
          <SelectionContent
            type={selectionType}
            close={() => setSelectionIsOpen(false)}
          />
        </FullModal>
      )}
      <Modal
        isOpen={!!trainSelectedItem}
        setIsOpen={(bol: boolean) => (!bol ? setTrainSelectedItem(null) : null)}
      >
        <TrainModalContent
          item={trainSelectedItem}
          close={() => setTrainSelectedItem(null)}
        />
      </Modal>
      <Modal
        isOpen={!!levelUpSelectedItem}
        setIsOpen={(bol: boolean) =>
          !bol ? setLevelUpSelectedItem(null) : null
        }
      >
        <LevelUpModalContent
          item={levelUpSelectedItem}
          close={() => setLevelUpSelectedItem(null)}
        />
      </Modal>
    </>
  );
};

export default Game;
