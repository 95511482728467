import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const GameActions = {
  start_match: "Match_start",
  attack: "Attack",
  cannon: "Cannon",
  break: "Break",
  defend: "Defend",
};

export const useSound = (): {
  gameSound: (props: { typeAction: string; petName?: string }) => void;
  toggleSoundPreference: () => void;
  isDisabledSound: boolean;
} => {
  const initialValue = Cookies.get("disabledSound") === "true";
  const [isDisabledSound, setIsDisabledSound] = useState<boolean>(initialValue);
  const gameSound = ({
    typeAction,
    petName,
  }: {
    typeAction: string;
    petName?: string;
  }): void => {
    if (isDisabledSound) {
      const audioPaths = {
        Attack: petName ? `/audios/pets/${petName}.wav` : "",
        Cannon: "/audios/Cannon.wav",
        Break: "/audios/Break.mp3",
        Defend: "/audios/Defense.wav",
        Match_start: "/audios/match_start1.mp3",
      };

      const audioPath = audioPaths[typeAction];

      if (audioPath) {
        const audio = new Audio(audioPath);

        const isMatchStart = typeAction === GameActions.start_match;

        audio.volume = isMatchStart ? 0.2 : 0.06;

        audio.play();
      }
    }
  };

  useEffect(() => {
    const expiresIn365Days = 365;
    Cookies.set("disabledSound", isDisabledSound, {
      expires: expiresIn365Days,
    });
  }, [isDisabledSound]);

  useEffect(() => {
    if (initialValue !== undefined) {
      setIsDisabledSound(initialValue);
    }
  }, [initialValue]);

  const toggleSoundPreference = (): void => {
    setIsDisabledSound(prev => !prev);
  };

  return { gameSound, toggleSoundPreference, isDisabledSound };
};

export default useSound;
