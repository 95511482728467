import { useFetch } from "../../../hooks/useFetch";
import { useFilter } from "../../../hooks/useFilters";
import { useGame } from "../../../hooks/useGame";
import { isActivated } from "../../../utils";
import {
  boostCardSchema,
  petsCardSchema,
  cannonsCardSchema,
} from "../../../utils/constants";
import { SelectionItem } from "../../UI/Items/SelectionItem";

interface Props {
  type: string;
  close: () => void;
}

export const SelectionContent = ({ type, close }: Props) => {
  const { userInventory, isLoadingUserInventory } = useFetch();
  const title = {
    pet: "Pets",
    cannon: "Cannons",
    boost: "Amulets",
  };

  const { applyFilter, renderFilters } = useFilter(title[type], true);

  const { setSelectedCards } = useGame();

  if (isLoadingUserInventory) {
    return (
      <div className="min-w-screen flex min-h-screen items-center justify-center">
        <div className="loading-circle"></div>
      </div>
    );
  }

  const handleSelectCard = card => {
    setSelectedCards(card, type);

    close();
  };

  const renderCards = () => {
    const filterType = {
      pet: petsCardSchema,
      cannon: cannonsCardSchema,
      boost: boostCardSchema,
    };

    return (
      <div className="mt-8 flex flex-col gap-8">
        <div className="mb-4 mt-8 flex flex-col gap-2 lg:flex-row">
          <h3>
            <b>{title[type]}</b>
          </h3>
        </div>
        <div>{renderFilters()}</div>
        <div className="item-center flex flex-wrap justify-center gap-6 lg:justify-start">
          {applyFilter(
            userInventory
              .filter(e => isActivated(e, filterType[type]))
              .filter(e => e.schema === filterType[type])
              .filter(e => {
                const keys = Object.keys(e?.data);
                return keys.includes("strength");
              }),
          ).map((item, _) => (
            <SelectionItem
              key={_}
              item={item}
              type={type}
              handleSelectCard={handleSelectCard}
              isModal
            />
          ))}
        </div>
      </div>
    );
  };

  return <div className="quests-div m-12">{renderCards()}</div>;
};
