import Image from "next/image";

import { useFetch } from "../../../../hooks/useFetch";
import { useGame } from "../../../../hooks/useGame";
import {
  cleanAndCapitalize,
  getAttrsToHideByType,
  getAttrsToShow,
  getMaxLevel,
  isActivated,
  manaAtrsFirst,
} from "../../../../utils";

export const SelectionItem = ({
  item,
  type,
  handleSelectCard,
  isModal = false,
}) => {
  const selectedFilter = {
    "Pet Cards": "pet",
    "Cannon Cards": "cannon",
    "Boost Cards": "boost",
  };

  const { selectedCards } = useGame();

  const { levelCap } = useFetch();

  const maxLevel = getMaxLevel(item, levelCap);

  const activate = isActivated(item, type);

  const data = {
    img: item?.data?.img || item?.img,
    name: item?.data?.name || item?.attributes?.name,
    ...item?.attributes,
  };
  const { petsData } = useFetch();

  const itemPetData = petsData?.find(
    (nft: { assetId: string }) => nft.assetId === item.assetId.toString(),
  );

  const attributes = manaAtrsFirst(Object.entries(data));

  const attributesToHide = getAttrsToHideByType(item?.type);
  const attributesToShow = getAttrsToShow(type);

  if (!selectedCards) {
    return null;
  }

  const minimalistTranslate = (attributeName: string) => {
    return (
      {
        "Mana Regen": "Mregen",
        Strength: "Str",
        Intelligence: "Int",
        Obedience: "Obed",
        Dexterity: "Dex",
        Durability: "Dur",
      }[attributeName] || attributeName
    );
  };

  return (
    <div
      onClick={() => {
        handleSelectCard(item);
      }}
      className={` cursor-pointer ${
        +selectedCards[type]?.assetId === +item?.assetId
          ? "!shadow-2xl !shadow-white"
          : " "
      } `}
    >
      <div
        className={
          (isModal
            ? "h-[300px] w-[200px]"
            : "h-[200px] w-[100px] md:h-[300px] md:w-[200px]") +
          " flip-card  bg-blue-700 text-center "
        }
      >
        <div className="flip-card-inner">
          <div className="flip-card-back peer !grid !grid-flow-row !grid-cols-2 justify-start bg-blue-700 text-center md:!flex">
            {activate && (
              <div className="hidden md:block">
                <p>Attributes</p>
              </div>
            )}
            {attributes.map((item, _) => {
              if (attributesToShow.includes(item[0]))
                return (
                  <div
                    key={_}
                    className="flex flex-col items-center justify-center text-center md:flex-row md:gap-2"
                  >
                    <span className="hidden overflow-hidden text-center md:block md:w-[100px]">
                      {cleanAndCapitalize(item[0] as string)}:
                    </span>{" "}
                    <span className=" block overflow-hidden text-center text-sm md:hidden md:w-[100px]">
                      {minimalistTranslate(
                        cleanAndCapitalize(item[0] as string),
                      )}
                    </span>{" "}
                    <div className="flex flex-row flex-nowrap gap-2 text-center md:w-[50px]">
                      <span className="flex flex-row flex-nowrap gap-2 text-center md:w-[50px]">
                        {item[1] as string}
                      </span>
                    </div>
                  </div>
                );

              return null;
            })}
          </div>

          <div className="flip-card-front peer-hover:hidden">
            <div
              className={
                (isModal ? "" : "hidden md:!flex") +
                " items-around mt-4 flex w-full flex-col justify-around"
              }
            >
              <span>{item?.attributes?.name}</span>
              {isModal && <small>{item?.assetId}</small>}
            </div>
            <div className="flex flex-1 items-center justify-center">
              {item?.img ? (
                <Image width={128} height={128} src={`${item?.img}`} />
              ) : (
                <div className="flip-card-front h-[256px] w-[128px] bg-blue-700 text-center">
                  Img Missing
                </div>
              )}
            </div>
            <div className="flex flex-col">
              {activate && (
                <div className="items-around mb-2 flex w-full justify-around gap-2">
                  <div>
                    Level: {item?.attributes?.level || 1}
                    {maxLevel > 0 ? "/" + maxLevel : null}
                  </div>

                  {item?.attributes?.hp && (
                    <div>HP: {item?.attributes?.hp || 0}</div>
                  )}
                </div>
              )}
              {itemPetData && (
                <div className="mb-2 flex items-center justify-center gap-2 text-sm">
                  <span className="text-[12px]">Ranked matches:</span>{" "}
                  <span className="text-[12px]">
                    {itemPetData?.gamesPlayed ?? 0}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
