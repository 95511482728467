import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";

import { PlayerOne } from "../../../../@types/socket";
import { battleDetailTranslate } from "../../../../utils";
import {
  BattleDeckItem,
  BattleDeckItemMobile,
} from "../../Items/BattleDeckItem";

type Props = {
  user: PlayerOne;
  opponent: PlayerOne;
  damageTaken: number;
  haveCannon: boolean;
};

type PropsMobile = Props & {
  deckIsOpen: boolean;
  setDeckIsOpen;
  isUser: boolean;
};

export const PlayerDetails = ({
  user,
  opponent,
  damageTaken,
  haveCannon,
}: Props) => {
  if (
    user.details.meleeOdds === undefined ||
    opponent.details.meleeOdds === undefined ||
    user.details.spellOdds === undefined ||
    opponent.details.spellOdds === undefined
  ) {
    return (
      <>
        <span>Loading odds...</span>
      </>
    );
  }

  const attackOdd =
    (user.details.meleeOdds.attack / 100) *
    (1 - opponent.details.meleeOdds.defend / 100) *
    (1 - opponent.details.meleeOdds.avoid / 100) *
    100;

  const attackOddCannon = haveCannon
    ? (user.details.spellOdds.attack / 100) *
      (1 - opponent.details.spellOdds.defend / 100) *
      (1 - opponent.details.spellOdds.avoid / 100) *
      100
    : 0;

  return (
    <div className="group relative ">
      <div className="absolute inset-0 flex  items-center justify-center ">
        <div className="invisible z-10 flex h-full w-full min-w-[300px] flex-col flex-wrap items-center justify-center gap-4 rounded-lg bg-[#000000ac] text-base transition-all group-hover:visible">
          <span className="text-center">
            Melee Odds <br /> Attack {attackOdd.toFixed(2)}% - Critical{" "}
            {user?.details?.meleeOdds?.critical?.toFixed(2) || 0}% - Avoid{" "}
            {user?.details?.meleeOdds?.avoid?.toFixed(2) || 0.0}% - Defend{" "}
            {user?.details?.meleeOdds?.defend || 0}%
          </span>
          <div className="flex gap-2">
            {Object.entries(
              {
                ...user?.details.pet,
              } || {},
            ).map(([key, value], _) => {
              if (["totalStrength", "cannon_damage"].includes(key))
                return (
                  <span className="text-center text-base" key={_}>
                    {battleDetailTranslate(key)}: {value}
                  </span>
                );

              return null;
            })}
          </div>
          {haveCannon && (
            <>
              <span className="text-center">
                Cannon Odds <br /> Attack {attackOddCannon.toFixed(2)}% - Avoid{" "}
                {user?.details?.spellOdds?.avoid?.toFixed(2) || 0.0}% - Defend{" "}
                {user?.details?.spellOdds?.defend || 0}%
              </span>
              <aside className={`flex items-center gap-5 `}>
                <span className="text-center text-sm">
                  Mana Cost: {user?.details.cannon?.mana_cost}
                </span>
                <div className="flex gap-2">
                  {Object.entries(
                    {
                      ...user?.details.spellOdds,
                    } || {},
                  ).map(([key, value], _) => {
                    if (["totalStrength", "cannon_damage"].includes(key))
                      return (
                        <span className="text-center text-sm" key={_}>
                          {battleDetailTranslate(key)}: {value}
                        </span>
                      );

                    return null;
                  })}
                </div>
              </aside>
            </>
          )}
        </div>
      </div>
      <div className="flex h-[auto] items-center justify-center gap-4 font-normal ">
        {user?.deck.cards.map((card, _) => (
          <BattleDeckItem
            key={_}
            item={card}
            type={card.type}
            damageTaken={damageTaken}
          />
        ))}
      </div>
    </div>
  );
};

export const PlayerDetailsMobile = ({
  user,
  opponent,
  deckIsOpen,
  setDeckIsOpen,
  damageTaken,
  haveCannon,
  isUser,
}: PropsMobile) => {
  return (
    <div className="group">
      <div className={`absolute inset-0 flex items-center justify-center`}>
        <div className="invisible z-[11] flex h-[110%] w-full flex-col items-center justify-center  gap-[3px] rounded-lg bg-[#000000ac] text-xs transition-all group-hover:visible  xs:max-w-screen-xs xs:gap-4 md:w-full md:max-w-none md:text-base">
          <span className="text-center">
            Melee Odds - Base Damage: {user?.details?.pet?.totalStrength} <br />{" "}
            Attack {user?.details?.meleeOdds?.attack}% - Critical{" "}
            {user?.details?.meleeOdds?.critical?.toFixed(2) || 0}% - Avoid{" "}
            {user?.details?.meleeOdds?.avoid?.toFixed(2) || 0.0}% - Defend{" "}
            {user?.details?.meleeOdds?.defend || 0}%
          </span>

          <div className="flex gap-2">
            {Object.entries(
              {
                ...user?.details.pet,
              } || {},
            ).map(([key, value], _) => {
              if (["cannon_damage"].includes(key))
                return (
                  <span className="text-center text-sm md:text-base" key={_}>
                    {battleDetailTranslate(key)}: {value}
                  </span>
                );

              return null;
            })}
          </div>
          {haveCannon && (
            <>
              <span className="text-center">
                Cannon Odds - Base Damge:{" "}
                {user?.details?.spellOdds?.cannon_damage} <br /> Attack{" "}
                {user?.details?.spellOdds?.attack || 0}% - Avoid{" "}
                {user?.details?.spellOdds?.avoid?.toFixed(2) || 0.0}% - Defend{" "}
                {user?.details?.spellOdds?.defend || 0}%
              </span>

              <div className="flex gap-2">
                {Object.entries(
                  {
                    ...user?.details.spellOdds,
                  } || {},
                ).map(([key, value], _) => {
                  if (["cannon_damae", "mana_cost"].includes(key))
                    return (
                      <span
                        className="text-center text-sm md:text-base"
                        key={_}
                      >
                        {battleDetailTranslate(key)}: {value}
                      </span>
                    );

                  return null;
                })}
              </div>
              <span className="text-center text-sm md:text-base">
                Mana Cost: {user?.details.cannon?.mana_cost}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="flex h-max flex-col items-center justify-center gap-1 font-normal">
        <div className={`main !h-[107.1px]`}>
          {user?.deck.cards.map((card, _) => (
            <BattleDeckItemMobile
              id={_}
              key={_}
              item={card}
              deckIsOpen={deckIsOpen}
              damageTaken={damageTaken}
              type={card.type}
            />
          ))}
        </div>
        <div
          className={`gamebtn !absolute left-24  ${
            isUser ? "top-[-20px]" : "bottom-[-20px]"
          } !z-[10] flex h-[32px] w-[32px] items-center justify-center rounded-lg pb-6`}
        >
          <button
            onClick={() => setDeckIsOpen(prev => !prev)}
            className="text-lg font-medium underline"
          >
            {deckIsOpen ? <FaRegEyeSlash /> : <FaRegEye />}
          </button>
        </div>
      </div>
    </div>
  );
};
